import styled from 'styled-components';
import { Modal } from 'react-overlays';
import { RenderModalBackdropProps } from 'react-overlays/cjs/Modal';
import { CloseButton } from './CloseButton';
import { useCallback } from 'react';

const Container = styled(Modal)`
    position: fixed;
    z-index: 1040;
    top: 50%;
    left: 50%;
    width: 100%;
    background: white;
    max-width: 700px;
    transform: translate(-50%, -50%);
    outline: none;
    max-height: 100%;
    overflow: auto;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 25px 25px 25px 25px;
`;

const Backdrop = styled.div`
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.overlay};
    opacity: 0.7;
`;

const StyledCloseButton = styled(CloseButton)`
    position: absolute;
    top: 0;
    right: 0;
`;

const renderBackdrop = (props: RenderModalBackdropProps) => <Backdrop {...props} className="backdrop" />;

type Props = {
    className?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
    onClose?: () => void;
};

export const Dialog: React.FunctionComponent<Props> = ({ className, style, children, onClose }: Props) => {
    const handleClose = useCallback(() => {
        onClose && onClose();
    }, [onClose]);
    return (
        <Container
            className={className}
            style={style}
            show={true}
            onBackdropClick={onClose ? handleClose : undefined}
            renderBackdrop={renderBackdrop}
        >
            <Content>
                <StyledCloseButton onClick={handleClose} />
                {children}
            </Content>
        </Container>
    );
};
