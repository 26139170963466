import 'react-phone-input-2/lib/high-res.css';
import PhoneInputBase from 'react-phone-input-2';
import styled, { useTheme } from 'styled-components';
import { useCallback, useEffect, useMemo } from 'react';
import hexRgb from 'hex-rgb';

type Props = {
    value: string;
    onChange: (value: string) => void;
    onBlur?: (event: React.FocusEvent) => void;
    error?: boolean;
    className?: string;
};

const preferredCountries = ['ca', 'us'];

const inputProps = {
    className: 'form-control data-hj-allow',
};

const containerStyle: React.CSSProperties = {
    height: '44px',
    width: '100%',
};

const inputStyleInit: React.CSSProperties = {
    height: '44px',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Museo Sans',
    appearance: 'none',
    border: 'none',
    borderRadius: '0',
};

const Container = styled.div<{ error?: boolean }>`
    width: 100%;

    .react-tel-input {
        width: 100%;

        .form-control {
            width: 100%;
        }
    }

    .flag-dropdown {
        background: none;
        border: none;

        &.open {
            background: none;
        }

        .selected-flag {
            background: none;
            &.open {
                background: none;
            }
        }
    }
`;

export const adjustPhoneNumber = (phone: string) => {
    if (!phone) {
        return '+1';
    }
    const newPhone = phone.replace(/[^\+0-9]/g, '');
    if (newPhone && newPhone[0] !== '+') {
        if (phone.length === 11) {
            return `+${phone}`;
        }
        return `+1${phone}`;
    }
    return newPhone;
};

export const PhoneInput = ({ value, onChange, error, onBlur }: Props) => {
    const theme = useTheme();

    const inputStyle = useMemo(
        () => ({
            ...inputStyleInit,
            background: error
                ? hexRgb(theme.colors.danger, { alpha: 0.2, format: 'css' })
                : hexRgb(theme.colors.primary, { alpha: 0.2, format: 'css' }),
        }),
        [error, theme]
    );
    const adjustedValue = adjustPhoneNumber(value);

    const handleChange = useCallback(
        (value: string) => {
            onChange(`+${value}`);
        },
        [onChange]
    );

    // This is hack to handle previous phone number format which did not require country code
    useEffect(() => {
        if (value !== adjustedValue) {
            onChange(adjustedValue);
        }
    }, [value, adjustedValue, onChange]);

    return (
        <Container>
            <PhoneInputBase
                inputProps={inputProps}
                containerStyle={containerStyle}
                inputStyle={inputStyle}
                enableSearch
                preferredCountries={preferredCountries}
                value={adjustedValue}
                country="ca"
                countryCodeEditable={false}
                onBlur={onBlur}
                onChange={handleChange}
            />
        </Container>
    );
};
