import styled from 'styled-components';

export const DialogTitle = styled.h3`
    text-align: left;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 25px;
    color: ${({ theme }) => theme.colors.secondary};
    margin-top: 0;
`;
