import { Dialog, DialogTitle } from 'components/atoms/Dialog';
import { FormError } from 'components/atoms/FormError';
import { FormRow } from 'components/atoms/FormRow';
import { Input } from 'components/atoms/Input';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from '@aws-amplify/auth';
import { Content, StyledForm, Fields, ActionContainer, LoginButton, MessageContainer } from './common';
import { errorToMessage } from '@magicship/utils/util';
import { navigateOnLogin } from './navigateOnLogin';
import { useModalContext } from './ModalContext';

type Props = {
    onClose: () => void;
};

type LoginData = {
    email: string;
    password: string;
    code: string;
    confirmPassword: string;
};

export const RestorePasswordDialog: React.FunctionComponent<Props> = ({ onClose }: Props) => {
    const { t } = useTranslation(['home', 'common']);
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<LoginData>({
        mode: 'onTouched',
    });
    const [codeSent, setCodeSent] = useState(false);
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const onSubmit = handleSubmit(async (data) => {
        try {
            setSubmitting(true);
            setError('');
            if (!codeSent) {
                await Auth.forgotPassword(data.email);
                setCodeSent(true);
            } else {
                await Auth.forgotPasswordSubmit(data.email, data.code, data.password);
                // TODO -This is workaround to clear old cookies to ensure not reaching RequestHeaderTooLarge error
                await Auth.signOut();
                await Auth.signIn(data.email, data.password);
                navigateOnLogin(router);
            }
        } catch (e) {
            setError(errorToMessage(e));
        } finally {
            setSubmitting(false);
        }
    });
    const emailError = errors?.email?.message ?? '';
    const passwordError = errors?.password?.message ?? '';
    const codeError = errors?.code?.message ?? '';
    const confirmPasswordError = errors?.confirmPassword?.message ?? '';

    const router = useRouter();
    const { showModal } = useModalContext();

    const handleSignup = useCallback(() => {
        showModal('signup');
    }, [showModal]);

    const requiredMessage = t<string>('common::Required field');

    return (
        <Dialog onClose={onClose}>
            <Content>
                <DialogTitle>{t('Login')}</DialogTitle>
                <StyledForm onSubmit={onSubmit}>
                    <Fields>
                        <FormRow label={t('email')} error={emailError}>
                            <Input
                                readOnly={codeSent}
                                error={Boolean(emailError)}
                                placeholder="email@gmail.com"
                                type="email"
                                {...register('email', { required: requiredMessage })}
                            />
                        </FormRow>
                        {codeSent && (
                            <>
                                <FormRow label={t('code')} error={codeError}>
                                    <Input
                                        error={Boolean(codeError)}
                                        type="number"
                                        {...register('code', { required: requiredMessage })}
                                    />
                                </FormRow>
                                <FormRow label={t('password')} error={passwordError}>
                                    <Input
                                        error={Boolean(passwordError)}
                                        placeholder="*******"
                                        type="password"
                                        {...register('password', { required: requiredMessage })}
                                    />
                                </FormRow>
                                <FormRow label={t('confirm password')} error={confirmPasswordError}>
                                    <Input
                                        error={Boolean(confirmPasswordError)}
                                        placeholder="*******"
                                        type="password"
                                        {...register('confirmPassword', {
                                            required: requiredMessage,
                                            validate: (value) =>
                                                value === getValues('password') || t<string>("Passwords don't match."),
                                        })}
                                    />
                                </FormRow>
                            </>
                        )}
                    </Fields>
                    {error && <FormError>{error}</FormError>}
                    <ActionContainer>
                        <LoginButton inProgress={submitting} type="submit" kind="secondary" fill="solid">
                            {codeSent ? t('Set password') : t('Send code')}
                        </LoginButton>
                        <MessageContainer>
                            <span>{t('Don’t have a MagicShip account?')}</span>
                            <a onClick={handleSignup}>{t('Sign up')}</a>
                        </MessageContainer>
                    </ActionContainer>
                </StyledForm>
            </Content>
        </Dialog>
    );
};
