import styled from 'styled-components';
import { Header } from 'components/parts/Header';
import { Footer } from 'components/parts/Footer';
import { WebsiteHead } from './WebsiteHead';
import { ModalContextProvider } from './login/ModalContext';
import { ModalsContext } from './Modals';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;

    main {
        flex-grow: 1;
    }
`;
export const BasicPage = ({
    metaTitle,
    metaDescription,
    children,
}: React.PropsWithChildren<{ metaTitle?: string; metaDescription?: string }>) => {
    return (
        <ModalContextProvider>
            <Container>
                <WebsiteHead metaTitle={metaTitle} metaDescription={metaDescription} />
                <Header />
                <main>{children}</main>
                <Footer />
                <ModalsContext />
            </Container>
        </ModalContextProvider>
    );
};
