import { FormError } from 'components/atoms/FormError';
import { FormRow } from 'components/atoms/FormRow';
import { Input } from 'components/atoms/Input';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from '@aws-amplify/auth';
import { StyledForm, Fields, ActionContainer, LoginButton } from './common';
import { errorToMessage } from '@magicship/utils/util';
import styled from 'styled-components';
import { toast } from 'react-toastify';

type Props = {
    email: string;
    onConfirm: () => void;
};

type ConfirmData = {
    code: string;
};

const ResendLink = styled.a<{ disabled?: boolean }>`
    cursor: pointer;
    color: ${({ theme, disabled }) => (disabled ? theme.text.placeholder : theme.colors.secondary)};
`;

export const ConfirmCode: React.FunctionComponent<Props> = ({ email, onConfirm }: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ConfirmData>({
        mode: 'onTouched',
    });
    const [error, setError] = useState('');
    const [submitting, setSubmittng] = useState(false);
    const onSubmit = handleSubmit(async (data) => {
        try {
            setSubmittng(true);
            setError('');
            await Auth.confirmSignUp(email, data.code);
            onConfirm();
        } catch (e) {
            setError(errorToMessage(e));
        } finally {
            setSubmittng(false);
        }
    });
    const { t } = useTranslation('home');
    const codeError = errors?.code?.message ?? '';

    const handleSendCode = useCallback(async () => {
        setSubmittng(true);
        try {
            await Auth.resendSignUp(email);
        } catch (e) {
            setError(errorToMessage(e));
        }
        toast.success(t('Code has been sent'));
        setSubmittng(false);
    }, [email, t]);

    const requiredMessage = t<string>('common::Required field');

    return (
        <StyledForm onSubmit={onSubmit}>
            <p>{t<string>('Please enter the code sent to your email')}</p>
            <Fields>
                <FormRow label={t('code')} error={codeError}>
                    <Input
                        error={Boolean(codeError)}
                        type="number"
                        {...register('code', { required: requiredMessage })}
                    />
                </FormRow>
            </Fields>
            {error && <FormError>{error}</FormError>}
            <div>
                {t('Did not receive code?')}{' '}
                <ResendLink disabled={submitting} onClick={handleSendCode}>
                    {t('Resend')}
                </ResendLink>
            </div>
            <ActionContainer>
                <LoginButton inProgress={submitting} type="submit" kind="secondary" fill="solid">
                    {t('Confirm')}
                </LoginButton>
            </ActionContainer>
        </StyledForm>
    );
};
