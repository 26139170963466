import styled from 'styled-components';
import { FormError } from './FormError';
import { FormLabel } from './FormLabel';
import infoIcon from 'public/icons/circle-info.svg';
import Image from 'next/image';
import { Tooltip } from './Tooltip';

type Props = {
    label?: string;
    detail?: React.ReactNode;
    children?: React.ReactNode;
    error?: string;
    className?: string;
    style?: React.CSSProperties;
    info?: string;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

const InfoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    > label {
        margin-bottom: 0;
    }

    > div {
        margin-left: 5px;
    }
`;

const Detail = styled.div`
    text-transform: lowercase;
`;

export const FormRow: React.FunctionComponent<Props> = ({
    children,
    label,
    error,
    className,
    style,
    info,
    detail,
}: Props) => {
    return (
        <Container className={className} style={style}>
            {label &&
                (info || detail ? (
                    <InfoContainer>
                        <FormLabel>{label}</FormLabel>
                        {info && (
                            <Tooltip content={info}>
                                <Image src={infoIcon} alt="info-icon" width="16" height="16" />
                            </Tooltip>
                        )}
                        {detail && <Detail>{detail}</Detail>}
                    </InfoContainer>
                ) : (
                    <FormLabel>{label}</FormLabel>
                ))}
            {children}
            {error && <FormError>{error}</FormError>}
        </Container>
    );
};
