import { useCallback } from 'react';
import { LoginDialog } from './login/LoginDialog';
import { useModalContext } from './login/ModalContext';
import { RestorePasswordDialog } from './login/RestorePasswordDialog';
import { SignupDialog } from './login/SignupDialog';

export const ModalsContext = () => {
    const { modal, showModal } = useModalContext();
    const handleClose = useCallback(() => {
        showModal(null);
    }, [showModal]);
    switch (modal) {
        case 'login':
            return <LoginDialog onClose={handleClose} />;
        case 'signup':
            return <SignupDialog onClose={handleClose} />;
        case 'resetPassword':
            return <RestorePasswordDialog onClose={handleClose} />;
    }
    return null;
};
