import styled from 'styled-components';
import hexRgb from 'hex-rgb';

export const Input = styled.input<{
    error?: boolean;
    bg?: 'default' | 'contrast';
}>`
    height: 44px;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Museo Sans';
    appearance: none;
    border: none;
    padding: 0 14px;
    background: ${({ theme, error, bg }) =>
        error
            ? bg !== 'contrast'
                ? hexRgb(theme.colors.danger, { alpha: 0.2, format: 'css' })
                : '#ECD0D3'
            : bg !== 'contrast'
            ? hexRgb(theme.colors.primary, { alpha: 0.2, format: 'css' })
            : theme.text.contrast};
    box-sizing: border-box;
    &::placeholder {
        color: ${({ theme }) => theme.text.placeholder};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }
`;
