import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    background: ${({ theme }) => theme.colors.secondary};
    appearance: none;
    border: none;
    width: 68px;
    height: 68px;
`;

type Props = {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
};

export const CloseButton: React.FunctionComponent<Props> = ({ className, style, onClick }: Props) => {
    return (
        <StyledButton className={className} style={style} onClick={onClick}>
            <svg width="34" height="34">
                <g stroke="white">
                    <line x1="1" y1="33" x2="33" y2="1" strokeWidth="4" />
                    <line x1="1" y1="1" x2="33" y2="33" strokeWidth="4" />
                </g>
            </svg>
        </StyledButton>
    );
};
