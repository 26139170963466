import { FacebookButton } from 'components/atoms/social/FacebookButton';
import { GoogleButton } from 'components/atoms/social/GoogleButton';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { Breakpoints } from 'styles/theme';

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > button {
        &:first-child {
            margin-bottom: 15px;
        }
    }

    @media ${Breakpoints.md} {
        flex-direction: row;

        > button {
            &:first-child {
                margin-bottom: 0;
                margin-right: 15px;
            }
        }
    }
`;

const Separator = styled.div`
    display: flex;
    margin: 10px 0;
    align-items: center;
`;

const SeparatorLine = styled.div`
    height: 2px;
    background: ${({ theme }) => theme.text.placeholder};
    flex-grow: 1;
`;

const SeparatorText = styled.span`
    margin: 0 12px;
`;

export const SocialButtons = () => {
    const { t } = useTranslation('home');
    return (
        <div>
            <Separator>
                <SeparatorLine />
                <SeparatorText>{t('or')}</SeparatorText>
                <SeparatorLine />
            </Separator>
            <Actions>
                <GoogleButton
                    onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}
                />
                <FacebookButton
                    onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })}
                />
            </Actions>
        </div>
    );
};
