import { Dialog } from 'components/atoms/Dialog';
import { FormError } from 'components/atoms/FormError';
import { FormRow } from 'components/atoms/FormRow';
import { Input } from 'components/atoms/Input';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Auth } from '@aws-amplify/auth';
import { Content, StyledForm, Fields, ActionContainer, LoginButton, MessageContainer } from './common';
import { errorToMessage } from '@magicship/utils/util';
import { useTracking } from 'components/hooks/useTracking';
import { SocialButtons } from './SocialButtons';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { PhoneInput } from 'components/atoms/PhoneInput';
import { ConfirmCode } from './ConfirmCode';
import { useModalContext } from './ModalContext';
import { navigateOnLogin } from './navigateOnLogin';

type Props = {
    onClose: () => void;
};

type SignupData = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    confirmPassword: string;
    phone: string;
};

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 1rem 0;
`;

export const SignupDialog: React.FunctionComponent<Props> = ({ onClose }: Props) => {
    const {
        register,
        handleSubmit,
        getValues,
        control,
        formState: { errors },
    } = useForm<SignupData>({
        mode: 'onTouched',
    });
    const [error, setError] = useState('');
    const pixel = useTracking();
    const [submitting, setSubmitting] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState('');
    const router = useRouter();
    const { showModal } = useModalContext();

    const submitSignup = useCallback(
        async (data: SignupData) => {
            try {
                setSubmitting(true);
                setError('');
                await Auth.signUp({
                    username: data.email,
                    password: data.password,
                    attributes: {
                        given_name: data.firstName,
                        family_name: data.lastName,
                        name: `${data.firstName} ${data.lastName}`,
                        phone_number: data.phone,
                    },
                });
                pixel.completeRegistration();
                // TODO -This is workaround to clear old cookies to ensure not reaching RequestHeaderTooLarge error
                await Auth.signOut();
                await Auth.signIn(data.email, data.password);
                navigateOnLogin(router);
            } catch (e) {
                if ((e as any).code === 'UserNotConfirmedException') {
                    setVerifyEmail(data.email);
                } else {
                    setError(errorToMessage(e));
                }
            } finally {
                setSubmitting(false);
            }
        },
        [router, pixel]
    );
    const onSubmit = handleSubmit(async (data) => {
        submitSignup(data);
    });
    const { t } = useTranslation(['home', 'common']);
    const firstNameError = errors?.firstName?.message ?? '';
    const lastNameError = errors?.lastName?.message ?? '';

    const emailError = errors?.email?.message ?? '';
    const passwordError = errors?.password?.message ?? '';
    const confirmPasswordError = errors?.confirmPassword?.message ?? '';
    const phoneError = errors?.phone?.message ?? '';

    const handleLogin = useCallback(() => {
        showModal('login');
    }, [showModal]);
    const requiredMessage = t<string>('common::Required field');
    const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'prod';

    const handleConfirmCode = useCallback(async () => {
        setVerifyEmail('');
        const data = getValues();
        setSubmitting(true);
        setError('');
        try {
            // TODO -This is workaround to clear old cookies to ensure not reaching RequestHeaderTooLarge error
            await Auth.signOut();
            await Auth.signIn(data.email, data.password);
            navigateOnLogin(router);
        } catch (e) {
            setError(errorToMessage(e));
        }
        setSubmitting(false);
    }, [getValues, router]);

    return (
        <Dialog onClose={onClose}>
            <Content>
                <Header>
                    <h1>{t('Welcome!')}</h1>
                    <p>{t('Sign up to get started with your MagicShip profile today')}</p>
                </Header>
                {verifyEmail ? (
                    <ConfirmCode email={verifyEmail} onConfirm={handleConfirmCode} />
                ) : (
                    <>
                        <StyledForm onSubmit={onSubmit}>
                            <Fields>
                                <FormRow label={t('First name')} error={firstNameError}>
                                    <Input
                                        error={Boolean(firstNameError)}
                                        placeholder={t('First name')}
                                        type="text"
                                        {...register('firstName', { required: requiredMessage })}
                                    />
                                </FormRow>
                                <FormRow label={t('last name')} error={lastNameError}>
                                    <Input
                                        error={Boolean(lastNameError)}
                                        placeholder={t('Last name')}
                                        type="text"
                                        {...register('lastName', { required: requiredMessage })}
                                    />
                                </FormRow>
                                <FormRow label={t('email')} error={emailError}>
                                    <Input
                                        error={Boolean(emailError)}
                                        placeholder="email@gmail.com"
                                        type="email"
                                        {...register('email', { required: requiredMessage })}
                                    />
                                </FormRow>
                                <FormRow label={t('phone')} error={phoneError}>
                                    <Controller
                                        render={({ field }) => {
                                            return (
                                                <PhoneInput
                                                    error={Boolean(phoneError)}
                                                    value={field.value ?? ''}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name={`phone`}
                                        rules={{
                                            required: requiredMessage,
                                            maxLength: {
                                                value: 15,
                                                message: t('Must not exceed {{max}} characters', { max: 15 }),
                                            },
                                            validate: {
                                                phone: (value) => {
                                                    return value
                                                        ? isMobilePhone(value, 'any', { strictMode: true }) ||
                                                              t<string>('Invalid phone number')
                                                        : true;
                                                },
                                            },
                                        }}
                                    />
                                </FormRow>
                                <FormRow label={t('password')} error={passwordError}>
                                    <Input
                                        error={Boolean(passwordError)}
                                        placeholder="*******"
                                        type="password"
                                        {...register('password', { required: requiredMessage })}
                                    />
                                </FormRow>
                                <FormRow label={t('confirm password')} error={confirmPasswordError}>
                                    <Input
                                        error={Boolean(confirmPasswordError)}
                                        placeholder="*******"
                                        type="password"
                                        {...register('confirmPassword', {
                                            required: requiredMessage,
                                            validate: (value) =>
                                                value === getValues('password') || t<string>("Passwords don't match."),
                                        })}
                                    />
                                </FormRow>
                            </Fields>
                            {error && <FormError>{error}</FormError>}
                            <ActionContainer>
                                <LoginButton inProgress={submitting} type="submit" kind="secondary" fill="solid">
                                    {t('Sign Up')}
                                </LoginButton>
                                <MessageContainer>
                                    <span>{t('Already have an account?')}</span>
                                    <a onClick={handleLogin}>{t('Login')}</a>
                                </MessageContainer>
                            </ActionContainer>
                        </StyledForm>
                        {!isProd && <SocialButtons />}
                    </>
                )}
            </Content>
        </Dialog>
    );
};
