import { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Overlay } from 'react-overlays';
import { OverlayProps } from 'react-overlays/cjs/Overlay';
import styled from 'styled-components';

type Props = {
    children: React.ReactNode;
    content: React.ReactNode;
    placement?: 'auto' | 'left' | 'right' | 'top' | 'bottom';
    target?: OverlayProps['target'];
};

const TooltipContainer = styled.div`
    position: absolute;
    z-index: 10000;
    max-width: 400px;
`;

const arrowPositionMap: Record<string, string> = {
    left: 'right: -5px;',
    right: 'left: -5px;',
    top: 'bottom: -5px;',
    bottom: 'top: -5px;',
};

const Arrow = styled.div<{ placement: string }>`
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: -1;

    &::before {
        content: '';
        position: absolute;
        transform: rotate(45deg);
        background: ${(p) => p.theme.colors.overlay};
        width: 10px;
        height: 10px;
        top: 0;
        left: 0;
    }

    ${(p) => arrowPositionMap[p.placement]}
`;

const TooltipBody = styled.div`
    padding: 5px 8px;
    color: ${(p) => p.theme.text.contrast};
    text-align: center;
    border-radius: 3px;
    background-color: ${(p) => p.theme.colors.main};
`;

export const Tooltip: React.FunctionComponent<Props> = ({ children, content, placement = 'auto', target }: Props) => {
    const [show, setShow] = useState(false);
    const handleShow = useCallback(() => setShow(true), []);
    const handleHide = useCallback(() => setShow(false), []);
    useEffect(() => {
        if (target) {
            const targetValue = typeof target === 'function' ? target() : target;
            const element = targetValue instanceof HTMLElement ? targetValue : targetValue?.current;
            if (element) {
                element.addEventListener('mouseenter', handleShow);
                element.addEventListener('mouseleave', handleHide);
                return () => {
                    element.removeEventListener('mouseenter', handleShow);
                    element.removeEventListener('mouseleave', handleHide);
                };
            }
        }
    }, [target, handleShow, handleHide]);
    const containerRef = useRef<HTMLDivElement>(null);
    return (
        <>
            {target ? (
                children
            ) : (
                <div ref={containerRef} onMouseEnter={handleShow} onMouseLeave={handleHide}>
                    {children}
                </div>
            )}
            <Overlay
                flip
                offset={[0, 5]}
                placement={placement}
                show={show && Boolean(content)}
                onHide={handleHide}
                container={typeof window === 'undefined' ? null : document.body}
                target={target ?? containerRef}
            >
                {({ props, arrowProps, placement }) => (
                    <TooltipContainer {...props}>
                        <Arrow {...arrowProps} placement={placement} style={arrowProps.style} />
                        <TooltipBody>{content}</TooltipBody>
                    </TooltipContainer>
                )}
            </Overlay>
        </>
    );
};
