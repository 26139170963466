import { Dialog, DialogTitle } from 'components/atoms/Dialog';
import { FormError } from 'components/atoms/FormError';
import { FormRow } from 'components/atoms/FormRow';
import { Input } from 'components/atoms/Input';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from '@aws-amplify/auth';
import { Content, StyledForm, Fields, ActionContainer, LoginButton, MessageContainer } from './common';
import { errorToMessage } from '@magicship/utils/util';

import { SocialButtons } from './SocialButtons';
import { ConfirmCode } from './ConfirmCode';
import { navigateOnLogin } from './navigateOnLogin';
import { useModalContext } from './ModalContext';

type Props = {
    onClose: () => void;
};

type LoginData = {
    email: string;
    password: string;
    newPassword: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
};

export const LoginDialog: React.FunctionComponent<Props> = ({ onClose }: Props) => {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<LoginData>({
        mode: 'onTouched',
    });
    const { t } = useTranslation('home');

    const router = useRouter();
    const { showModal } = useModalContext();

    const [error, setError] = useState('');
    const [submitting, setSubmittng] = useState(false);
    const [forcePasswordChange, setForcePasswordChange] = useState(null);
    const [verifyEmail, setVerifyEmail] = useState('');
    const submitLogin = useCallback(
        async (data: LoginData) => {
            try {
                setSubmittng(true);
                setError('');
                if (forcePasswordChange) {
                    await Auth.completeNewPassword(forcePasswordChange, data.newPassword, {
                        given_name: data.firstName,
                        family_name: data.lastName,
                        name: `${data.firstName} ${data.lastName}`,
                    });
                }
                // TODO -This is workaround to clear old cookies to ensure not reaching RequestHeaderTooLarge error
                await Auth.signOut();
                const user = await Auth.signIn(data.email, data.password);
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setForcePasswordChange(user);
                    setError(t('Please change temporary password'));
                } else {
                    navigateOnLogin(router);
                }
            } catch (e) {
                if ((e as any).code === 'UserNotConfirmedException') {
                    try {
                        await Auth.resendSignUp(data.email);
                    } catch (e) {}
                    setVerifyEmail(data.email);
                } else {
                    setError(errorToMessage(e));
                }
            } finally {
                setSubmittng(false);
            }
        },
        [forcePasswordChange, router, t]
    );
    const onSubmit = handleSubmit(submitLogin);
    const emailError = errors?.email?.message ?? '';
    const passwordError = errors?.password?.message ?? '';
    const newPasswordError = errors?.newPassword?.message ?? '';
    const confirmPasswordError = errors?.confirmPassword?.message ?? '';
    const firstNameError = errors?.firstName?.message ?? '';
    const lastNameError = errors?.lastName?.message ?? '';

    const handleSignup = useCallback(() => {
        showModal('signup');
    }, [showModal]);

    const handleConfirmCode = useCallback(async () => {
        setVerifyEmail('');
        await submitLogin(getValues());
    }, [submitLogin, getValues]);

    const handleForgotPassword = useCallback(async () => {
        console.log('handleForgotPassword');
        showModal('resetPassword');
    }, [showModal]);

    const requiredMessage = t<string>('common::Required field');

    const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'prod';

    return (
        <Dialog onClose={onClose}>
            <Content>
                <DialogTitle>{t('Login')}</DialogTitle>
                {verifyEmail ? (
                    <ConfirmCode email={verifyEmail} onConfirm={handleConfirmCode} />
                ) : (
                    <>
                        <StyledForm onSubmit={onSubmit}>
                            <Fields>
                                <FormRow label={t('email')} error={emailError}>
                                    <Input
                                        error={Boolean(emailError)}
                                        placeholder="email@gmail.com"
                                        type="email"
                                        disabled={Boolean(forcePasswordChange)}
                                        {...register('email', { required: requiredMessage })}
                                    />
                                </FormRow>
                                <FormRow label={t('password')} error={passwordError}>
                                    <Input
                                        error={Boolean(passwordError)}
                                        placeholder="*******"
                                        type="password"
                                        disabled={Boolean(forcePasswordChange)}
                                        {...register('password', { required: requiredMessage })}
                                    />
                                </FormRow>
                                {forcePasswordChange && (
                                    <>
                                        <FormRow label={t('First name')} error={firstNameError}>
                                            <Input
                                                error={Boolean(firstNameError)}
                                                placeholder={t('First name')}
                                                type="text"
                                                {...register('firstName', { required: requiredMessage })}
                                            />
                                        </FormRow>
                                        <FormRow label={t('last name')} error={lastNameError}>
                                            <Input
                                                error={Boolean(lastNameError)}
                                                placeholder={t('Last name')}
                                                type="text"
                                                {...register('lastName', { required: requiredMessage })}
                                            />
                                        </FormRow>
                                        <FormRow label={t('new password')} error={newPasswordError}>
                                            <Input
                                                error={Boolean(newPasswordError)}
                                                placeholder="*******"
                                                type="password"
                                                {...register('newPassword', { required: requiredMessage })}
                                            />
                                        </FormRow>
                                        <FormRow label={t('confirm password')} error={confirmPasswordError}>
                                            <Input
                                                error={Boolean(confirmPasswordError)}
                                                placeholder="*******"
                                                type="password"
                                                {...register('confirmPassword', {
                                                    required: requiredMessage,
                                                    validate: (value) =>
                                                        value === getValues('newPassword') ||
                                                        t<string>("Passwords don't match."),
                                                })}
                                            />
                                        </FormRow>
                                    </>
                                )}
                            </Fields>
                            {error && <FormError>{error}</FormError>}
                            <ActionContainer>
                                <LoginButton inProgress={submitting} type="submit" kind="secondary" fill="solid">
                                    {Boolean(forcePasswordChange) ? t('Set Password') : t('Login')}
                                </LoginButton>
                                <MessageContainer>
                                    <span>{t('Don’t have a MagicShip account?')}</span>
                                    <a onClick={handleSignup}>{t('Sign up')}</a>
                                </MessageContainer>
                                <MessageContainer>
                                    <a onClick={handleForgotPassword}>{t('Forgot Password?')}</a>
                                </MessageContainer>
                            </ActionContainer>
                        </StyledForm>
                        {!isProd && <SocialButtons />}
                    </>
                )}
            </Content>
        </Dialog>
    );
};
