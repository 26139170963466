import styled from 'styled-components';

const StyledButton = styled.button`
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    max-width: 300px;
    font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;
    border-radius: 2px;
    height: 40px;
    padding: 1px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    background-color: #4267b2;
    color: white;
    font-weight: 600;

    &:hover {
        background: #3f7ce8;
        color: #eee;
    }

    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }
`;

const SocialLogo = styled.svg`
    display: inline-block;
    height: 100%;
    margin-right: 12px;
    padding: 6px;
    text-align: center;
    vertical-align: middle;
    border-radius: 2px;
    box-sizing: border-box;
`;

type Props = {
    onClick?: () => void;
};

export const FacebookButton = ({ onClick }: Props) => {
    return (
        <StyledButton onClick={onClick}>
            <span>
                <SocialLogo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216" color="#ffffff">
                    <path
                        fill="#ffffff"
                        d="
          M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9
          11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1
          11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2
          15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3
          11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"
                    ></path>
                </SocialLogo>
            </span>
            <span>Continue with Facebook</span>
        </StyledButton>
    );
};
