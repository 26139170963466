import styled from 'styled-components';
import { Breakpoints } from 'styles/theme';
import { Button } from 'components/atoms/Button';
import { FormError } from 'components/atoms/FormError';

export const Content = styled.div`
    padding: 1rem 0 1rem;
    width: 100%;
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    > ${FormError} {
        text-align: center;
    }
`;

export const Title = styled.h3`
    text-align: left;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 25px;
    color: ${({ theme }) => theme.colors.secondary};
`;

export const LoginButton = styled(Button)`
    margin: 1rem 0;
    align-self: center;
`;

export const Fields = styled.div`
    display: grid;
    gap: 0 1rem;
    grid-template-columns: 1fr;

    @media ${Breakpoints.md} {
        grid-template-columns: 1fr 1fr;
    }
`;

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 22px;
    color: ${({ theme }) => theme.colors.overlay};
    align-items: center;
    padding: 1rem 0 0;
    text-align: center;
    justify-content: center;
    a {
        color: ${({ theme }) => theme.colors.secondary};
        font-weight: bold;
        margin: 5px 0 0;
    }

    @media ${Breakpoints.md} {
        flex-direction: row;
        align-items: center;
        padding: 0;

        a {
            margin: 0 0 0 0.5rem;
        }
    }
`;

export const ActionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
