import { NextRouter } from 'next/router';

export const navigateOnLogin = (router: NextRouter) => {
    const shop = router.query['shop'];
    if (shop) {
        router.push(`/settings?shop=${shop}`);
    } else {
        router.push('/shipments');
    }
};
